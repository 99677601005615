import { HeadFC, PageProps } from "gatsby"
import React from "react"

import { Box, Wrap, useDisclosure } from "@chakra-ui/react"
import { HeadSEO } from "../components/head"
import { Faq } from "../mocks/faq"
import "../styles/politicaeprivacidade.css"
import { capitalizeFirstLetter } from "../utils/capitalizeFirstLetter"
import { ResaleForm } from "../components/pages/contact/resale"

const RevendaPage: React.FC<PageProps> = () => {
  const [seeAll, setSeeAll] = React.useState(false)

  return (
    <body id="duvidas">
      <div className="container">
        <div className="row header">
          <div className="col">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <span className="h1">Quero revender</span>
              <p className="text-subtitle">
                Seja um revendedor Verde Campo! Leve produtos saudáveis, naturais e de alta qualidade para seus clientes. Como parceiro, você terá acesso a condições especiais, suporte exclusivo e um portfólio completo de alimentos nutritivos. Cadastre-se agora e faça parte dessa rede de sucesso!
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-2 mx-auto">
          <ResaleForm />
        </div>
      </div>
      <div className="container pt-5">
        <div className="row header">
          <div className="col">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <span className="h1">Perguntas frequentes</span>
              <p className="text-subtitle">
                Encontre as respostas para perguntas frequentes sobre nossa
                empresa, processos seletivos e oportunidades de carreira
              </p>
            </div>
          </div>
        </div>

        <div className={seeAll ? "row show--more" : "row"} id="duvidasList">
          <Wrap margin="20px auto" gap={6}>
            {Faq.map(i => (
              <Box w={["100%", "100%", "47%"]}>
                <div className="card-duvida">
                  <div className="row m-0 p-3">
                    <div className=" d-flex flex-column py-3 align-items-center"></div>

                    <div className="col-10">
                      <span className="h5">{i.title}</span>
                      <p className="card-paragraph">
                        {" "}
                        <div dangerouslySetInnerHTML={{ __html: i.text }} />
                      </p>
                    </div>
                  </div>
                </div>
              </Box>
            ))}
          </Wrap>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-center">
              <a
                href={!seeAll ? "#historia" : "javascript:void(0)"}
                id="showMore"
                onClick={() => setSeeAll(!seeAll)}
                className="btn btn-card-line outline px-5"
              >
                {!seeAll ? "Ver mais" : "Ver menos"}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="contact-list--header">
          <span className="contact-list--title">Nossos contatos</span>
          <span className="contact-list--subtitle">
            Entre em contato conosco para obter assistência, esclarecer dúvidas
            ou buscar informações adicionais.
          </span>
        </div>
        <div className="contact-list">
          <div className="contact-item">
            <svg
              width="80"
              height="81"
              viewBox="0 0 80 81"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="-0.000488281"
                y="0.842896"
                width="80.0002"
                height="80.0002"
                rx="16"
                fill="#025F1D"
              />
              <path
                d="M57.9997 35.843L43.1467 42.198C41.1368 43.058 38.8624 43.058 36.8524 42.198L21.9995 35.843"
                stroke="white"
                stroke-width="3.00001"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <rect
                x="21.9995"
                y="26.843"
                width="36.0002"
                height="30.0001"
                rx="8.00004"
                stroke="white"
                stroke-width="3.00001"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div className="contact--text">
              <span>E-mail</span>
              <a href="mailto:sac@verdecampo.com.br">sac@verdecampo.com.br</a>
            </div>
          </div>

          <div className="contact-item">
            <svg
              width="80"
              height="81"
              viewBox="0 0 80 81"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="-0.000244141"
                y="0.842896"
                width="80.0002"
                height="80.0002"
                rx="16"
                fill="#FFB600"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M37.7119 43.1312C35.3719 40.7912 33.6078 38.1712 32.4358 35.5132C32.1878 34.9512 32.3338 34.2932 32.7678 33.8592L34.4059 32.2232C35.7479 30.8812 35.7479 28.9831 34.5759 27.8111L32.2278 25.4631C30.6658 23.9011 28.1338 23.9011 26.5718 25.4631L25.2678 26.7671C23.7858 28.2491 23.1678 30.3872 23.5678 32.5072C24.5558 37.7332 27.5918 43.4552 32.4898 48.3532C37.3879 53.2513 43.1099 56.2873 48.3359 57.2753C50.4559 57.6753 52.5939 57.0573 54.0759 55.5753L55.3779 54.2733C56.94 52.7113 56.94 50.1792 55.3779 48.6172L53.0319 46.2712C51.8599 45.0992 49.9599 45.0992 48.7899 46.2712L46.9839 48.0792C46.5499 48.5132 45.8919 48.6592 45.3299 48.4112C42.6719 47.2372 40.0519 45.4712 37.7119 43.1312Z"
                stroke="white"
                stroke-width="3.00001"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div className="contact--text">
              <span>Telefone</span>
              <a href="tel:08000310426">0800 031 0426</a>
            </div>
          </div>

          <div className="contact-item">
            <svg
              width="80"
              height="81"
              viewBox="0 0 80 81"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="0.842896"
                width="80.0002"
                height="80.0002"
                rx="16"
                fill="#FF6A14"
              />
              <path
                d="M40.7082 30.3808C41.0984 30.7709 41.0984 31.4052 40.7082 31.7954C40.318 32.1855 39.6838 32.1855 39.2936 31.7954C38.9034 31.4052 38.9034 30.7709 39.2936 30.3808C39.6838 29.9906 40.316 29.9906 40.7082 30.3808"
                stroke="white"
                stroke-width="3.00001"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M48.6237 31.2931V31.2931C48.6237 33.6361 47.5832 35.8551 45.7845 37.3557L42.9573 39.7127C41.8308 40.6531 40.9885 41.8876 40.5243 43.2802L40 44.8448L39.4778 43.2782C39.0136 41.8876 38.1713 40.6511 37.0448 39.7107L34.2176 37.3537C32.4169 35.8531 31.3784 33.6341 31.3784 31.2911V31.2911V31.3351C31.3764 25.9569 35.8323 22.8356 40 22.8356C44.1678 22.8356 48.6237 25.9569 48.6237 31.3371"
                stroke="white"
                stroke-width="3.00001"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M27.997 40.843C26.0902 40.843 24.4475 42.1896 24.0733 44.0604L22.0725 54.0646C21.5763 56.5396 23.4711 58.8506 25.9961 58.8506H54.0059C56.531 58.8506 58.4258 56.5396 57.9296 54.0646L55.9287 44.0604C55.5546 42.1896 53.9119 40.843 52.0051 40.843"
                stroke="white"
                stroke-width="3.00001"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div className="contact--text">
              <span>Endereço</span>
              <a
                href="https://maps.app.goo.gl/vjzPobQN5KsciQ9M6"
                target="_blank"
              >
                Av Luiz Gomide, 500, Lavras - MG
              </a>
            </div>
          </div>

          <div className="contact-item">
            <svg
              width="80"
              height="81"
              viewBox="0 0 80 81"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="0.842896"
                width="80.0002"
                height="80.0002"
                rx="16"
                fill="#8BC400"
              />
              <path
                d="M43.8281 50.0831C42.5741 50.6011 41.276 50.8431 40 50.8431C34.478 50.8431 30 46.3651 30 40.8431C30 35.321 34.478 30.843 40 30.843C45.5221 30.843 50.0001 35.321 50.0001 40.8431V42.5091C50.0001 44.0431 48.7561 45.2871 47.2221 45.2871C45.6881 45.2871 44.4441 44.0431 44.4441 42.5091V40.8431C44.4441 38.3891 42.4541 36.399 40 36.399V36.399C37.546 36.399 35.556 38.3891 35.556 40.8431C35.556 43.2971 37.546 45.2871 40 45.2871C42.4541 45.2871 44.4441 43.2971 44.4441 40.8431"
                stroke="white"
                stroke-width="3.00001"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.378 24.2209V24.2209C21.542 26.0569 21.542 29.0349 23.378 30.8709V30.8709C25.214 32.707 25.214 35.685 23.378 37.521V37.521C21.542 39.357 21.542 42.335 23.378 44.171V44.171C25.214 46.007 25.214 48.985 23.378 50.821V50.821C21.542 52.657 21.542 55.6351 23.378 57.4711V57.4711C25.214 59.3071 28.192 59.3071 30.028 57.4711V57.4711C31.864 55.6351 34.842 55.6351 36.678 57.4711V57.4711C38.514 59.3071 41.4921 59.3071 43.3281 57.4711V57.4711C45.1641 55.6351 48.1421 55.6351 49.9781 57.4711V57.4711C51.8141 59.3071 54.7921 59.3071 56.6281 57.4711V57.4711C58.4641 55.6351 58.4641 52.657 56.6281 50.821V50.821C54.7921 48.985 54.7921 46.007 56.6281 44.171V44.171C58.4641 42.335 58.4641 39.357 56.6281 37.521V37.521C54.7921 35.685 54.7921 32.707 56.6281 30.8709V30.8709C58.4641 29.0349 58.4641 26.0569 56.6281 24.2209V24.2209C54.7921 22.3849 51.8141 22.3849 49.9781 24.2209V24.2209C48.1421 26.0569 45.1641 26.0569 43.3281 24.2209V24.2209C41.4921 22.3849 38.514 22.3849 36.678 24.2209V24.2209C34.842 26.0569 31.864 26.0569 30.028 24.2209V24.2209C28.19 22.3829 25.214 22.3829 23.378 24.2209Z"
                stroke="white"
                stroke-width="3.00001"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div className="contact--text">
              <span>Caixa Postal</span>
              <a href="javascript:void(0)">3164</a>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12"></div>
        </div>
      </div>

      <div className="work--benefits">
        <div className="container py-5">
          <div className="row flex-wrap-reverse">
            <div className="col-xl-6 mb-5 mt-xl-0 pt-5 pt-xl-0">
              <div className="d-flex flex-column  align-items-center align-items-xl-start">
                <span className="work-flag text-center text-xl-left text-alt h6">
                  Trabalhe conosco
                </span>
                <span className="h1 text-xl-left">
                  Quer fazer parte do nosso time?
                </span>
                <a
                  href="https://verdecampo.vagas.solides.com.br/"
                  className="btn btn-fazerparte"
                  target="_blank"
                >
                  Quero fazer parte
                </a>
              </div>
            </div>
            <div className="col-xl-6">
              <ul className="list-unstyled d-block pb-5 d-xl-none">
                <li className="d-flex flex-row">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="32" height="32" rx="16" fill="#F1F5F4" />
                    <path
                      d="M12.228 15.222L16.005 19L23.68 11.325C22.1 8.735 19.256 7 16 7C11.029 7 7 11.029 7 16C7 20.971 11.029 25 16 25C20.632 25 24.443 21.5 24.941 17"
                      stroke="#00615C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="text-work">
                    Desenvolvimento profissional e crescimento garantidos.
                  </span>
                </li>
                <li className="d-flex flex-row">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="32" height="32" rx="16" fill="#F1F5F4" />
                    <path
                      d="M12.228 15.222L16.005 19L23.68 11.325C22.1 8.735 19.256 7 16 7C11.029 7 7 11.029 7 16C7 20.971 11.029 25 16 25C20.632 25 24.443 21.5 24.941 17"
                      stroke="#00615C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="text-work">
                    Ambiente de trabalho colaborativo e inspirador.
                  </span>
                </li>
                <li className="d-flex flex-row">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="32" height="32" rx="16" fill="#F1F5F4" />
                    <path
                      d="M12.228 15.222L16.005 19L23.68 11.325C22.1 8.735 19.256 7 16 7C11.029 7 7 11.029 7 16C7 20.971 11.029 25 16 25C20.632 25 24.443 21.5 24.941 17"
                      stroke="#00615C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="text-work">
                    Oportunidades de inovação e aprendizado constante.
                  </span>
                </li>
                <li className="d-flex flex-row">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="32" height="32" rx="16" fill="#F1F5F4" />
                    <path
                      d="M12.228 15.222L16.005 19L23.68 11.325C22.1 8.735 19.256 7 16 7C11.029 7 7 11.029 7 16C7 20.971 11.029 25 16 25C20.632 25 24.443 21.5 24.941 17"
                      stroke="#00615C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="text-work">
                    Equipe engajada e motivada, pronta para enfrentar desafios.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container">
        <div className="card-reclamacao py-5">
          <div className="row">
            <div className="col-xl-6 col-10 text-center mx-auto">
              <span className="h2 text-center mb-1">
                Formulário de Contato
              </span>

              <p className="lead mt-0">
                Clique abaixo para preencher o formulário, e nossa equipe entrará em contato em breve para fornecer mais informações.
              </p>
              <div className="d-flex flex-column align-items-center">
                <a onClick={onOpen} className="btn btn-duvida my-1">
                  Quero ser um revendedor
                </a>

              </div>
            </div>
          </div>
        </div>
      </div> */}
    </body>
  )
}

export default RevendaPage

export const Head: HeadFC = () =>
  HeadSEO(capitalizeFirstLetter(`Seja um Revendedor de Produtos Verde Campo | Cresça com Qualidade`), "Torne-se um revendedor autorizado dos produtos Verde Campo e ofereça aos seus clientes qualidade e confiança. Comece agora e aumente suas vendas com uma marca reconhecida no mercado!", "")
